<template>
  <Text title="Roles">
    <div class="flex flex-col justify-center items-center py-10 text-lg">
      <h1 class="mb-10 font-semibold text-2xl text-center">Roles</h1>
      <p class="max-w-xs lg:max-w-6xl ">As an <span class="font-semibold">entrepreneur</span>. I focus on projects for faster greening of cities and
        restoration of nature. Innovating for nature and people with technology and a
        business approach. <i>Urbangreenr</i> is set up to provide climate solutions locally
        and jointly.</p>
      <p class="max-w-xs lg:max-w-6xl mt-5 mb-10">As a <span class="font-semibold">digital product manager</span> I help a company move forward. I
        coordinate input from teams, help make decisions for the right product, align with company
        strategy and vision, and constantly advocate for end users.</p>
    </div>
  </Text>
  <hr>
  <projects />
  <hr>
  <Text title="Advocate for better products and services for a better world">
    <div class="flex flex-col justify-center items-center py-10">
      <h1 class="mb-10 font-semibold text-2xl text-center">Advocate for better services</h1>
      <p class="max-w-xs lg:max-w-6xl">
        Let's create better services and products by exploring the original ideas you have directly with
        the end user. How different it is if you throw your assumptions overboard and look at what
        really works and is desired and will actually be used by people. It is interesting to know that
        human nature does not fundamentally change in our rapidly changing world. A good reason to
        make the interaction with computers more humane. Everything that influences the interaction
        with your service or product is important. Let's turn ideas into workable, profitable products
        and services that people will love.
      </p>
    </div>
  </Text>
  <Text title="More attention for UX - more value">
    <div class="flex flex-col justify-center items-center py-10">
      <h1 class="mb-10 font-semibold text-2xl text-center">More attention for UX - more value</h1>
      <p class="max-w-xs lg:max-w-6xl">
        Designing for user experience arguably saves you time and money, increases your sales,
        increases loyalty to your brand or company, and makes your products and services safer and
        more effective. Designing for user experience improves much more than your products and
        services. It calls for better composed and more diverse teams and thus improves the culture
        as well. Work becomes more meaningful and enjoyable when people are allowed to work on
        services or products that are beautiful, work well and produce satisfying results. That way it
        will also increase productivity.
      </p>
    </div>
  </Text>
  <Text title="More value for a better world">
    <div class="flex flex-col justify-center items-center py-10">
      <h1 class="mb-10 font-semibold text-2xl text-center">More value for a better world</h1>
      <p class="max-w-xs lg:max-w-6xl">
        I am convinced that it makes sense to always take climate change into account when
        developing a new product or service. Keeping this urgency in mind during any development
        process can provide unforeseen solutions for a better world. When designing and developing
        services and products, we can take sustainable development into account by looking at the
        three areas that encompass either the economic, environmental or social aspects of
        sustainability.
      </p>
    </div>
  </Text>
  <hr>
</template>

<script>
import Text from "@/components/content/Text";
import Line from "@/components/Line";
import Projects from "@/components/projects/Projects";

export default {
  name: "Content",
  components: {Projects, Line, Text}
}
</script>

<style scoped>

</style>
