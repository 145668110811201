<template>
  <div class="flex justify-center lg:h-96">
    <div class="flex justify-evenly items-center w-2/3 flex-col lg:flex-row lg:p-0 xs:w-screen">
      <div>
        <img src="../../public/assets/beginfoto.png" draggable="false" alt="Website logo" class="rounded-full">
      </div>
      <div class="mt-10 lg:mt-0 xs:justify-center xs:w-80">
        <p class="max-w-md lg:max-w-2xl lg:mt-20 lg:ml-20 font-semibold">
          "You will find me at the crossroads of Design, Technology and Entrepreneurship.<br>
          I’m especially acting for nature. I believe that restoring nature
          is key to substantial improvement for everyone.”
        </p>
        <p class="my-5 text-right">Liselotte Dullemond</p>
      </div>
    </div>
  </div>
  <hr>
</template>

<script>
import Line from "@/components/Line";

export default {
  name: "Header",
  components: {
    Line
  }
}
</script>
