<template>
  <div class="my-12 bg-gray-100 rounded-2xl m-5 max-w-xs xl:max-w-xl">
    <div class="text-center">
      <div>
        <h1 class="text-xl font-semibold py-5">{{ title }}</h1>
      </div>
      <div class="flex justify-center">
        <img :src="image" alt="project1" draggable="false" class=" aspect-auto rounded-t-m max-h-80">
      </div>
      <div>
        <p class="p-5">
          <slot/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectItem",
  props: ['title', 'image', 'height']
}
</script>
