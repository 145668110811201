<template>
  <Header/>
  <Content/>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Content from "@/components/content/Content";
import Projects from "@/components/projects/Projects";

export default {
  name: 'App',
  components: {
    Projects,
    Content,
    Footer,
    Header
  }
}
</script>

<style>

@layer base {
  @font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/pt-sans-v16-latin-regular.eot'), /* IE9 Compat Modes */
    url('../public/fonts/pt-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/pt-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/pt-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/pt-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/pt-sans-v16-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
  }
}

/**{*/
/*  user-select: none;*/
/*  font-family: "PT Sans", serif;*/
/*}*/
</style>
