<template>
  <div class="w-full bg-gray-600 h-3" />
</template>

<script>
export default {
  name: "Line"
}
</script>

<style scoped>

</style>
