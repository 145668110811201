<template>
  <div>
    <h1 class="text-center font-semibold py-20 text-2xl mt-10">Projects</h1>
    <div class="flex justify-center flex-wrap flex-row">
      <ProjectItem title="Urbangreenr" image="/assets/project-urbangreenr.jpeg" height="50">
        Platform contributes to
        accelerating urban greening.
      </ProjectItem>
      <ProjectItem title="Restaurant Menu" image="/assets/project-restaurant.jpeg" height="50">
        UX research & design for a digital
        restaurant application.
      </ProjectItem>
      <ProjectItem title="Producer" image="/assets/project-producer.png">
        Service for digital producers. To quickly find
        experts, gear and services.
      </ProjectItem>

    </div>
  </div>
</template>

<script>
import ProjectItem from "@/components/projects/ProjectItem";

export default {
  name: "Projects",
  components: {ProjectItem}
}
</script>

<style scoped>

</style>
